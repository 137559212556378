.grid_container {
  width: 100%;
  display: grid;
  place-items: center;
  grid-template-rows: auto auto auto;
  grid-template-columns: 50% 50%;
}
@media (max-width: 37.5em) {
  .grid_container {
    display: block;
  }
}

.grid_item {
  padding: 5%;
}
.grid_item.photo--1 {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: auto;
}
.grid_item.photo--2 {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: auto;
}
.grid_item.photo--3 {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: auto;
}

*, *:before, *:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 1.7;
  color: black;
  padding: 2%;
  text-align: center;
}

html {
  font-size: 62.5%;
}
@media (max-width: 75em) {
  html {
    font-size: 56.25%;
  }
}
@media (max-width: 56.25em) {
  html {
    font-size: 50%;
  }
}
@media (max-width: 150em) {
  html {
    font-size: 75%;
  }
}

.button:link, .button:visited {
  text-transform: uppercase;
  text-decoration: none;
  padding: 1rem 3rem;
  display: inline-block;
  text-align: center;
  border-radius: 10rem;
  transition: all 0.2s;
  position: relative;
  color: white;
  border: 1px solid white;
  background-color: #624870;
}
.button:active {
  transform: translateY(-3px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}
.button.resume_button {
  background-color: #624870;
  color: white;
  border: 1px solid white;
}

.grid_container {
  width: 100%;
  display: grid;
  place-items: center;
  grid-template-rows: auto auto auto;
  grid-template-columns: 50% 50%;
}
@media (max-width: 37.5em) {
  .grid_container {
    display: block;
  }
}

.grid_item {
  padding: 5%;
}
.grid_item.photo--1 {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: auto;
}
.grid_item.photo--2 {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: auto;
}
.grid_item.photo--3 {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: auto;
}

.grid_container {
  width: 100%;
  display: grid;
  place-items: center;
  grid-template-rows: auto auto auto;
  grid-template-columns: 50% 50%;
}
@media (max-width: 37.5em) {
  .grid_container {
    display: block;
  }
}

.grid_item {
  padding: 5%;
}
.grid_item.photo--1 {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: auto;
}
.grid_item.photo--2 {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: auto;
}
.grid_item.photo--3 {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: auto;
}

*, *:before, *:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 1.7;
  color: black;
  padding: 2%;
  text-align: center;
}

html {
  font-size: 62.5%;
}
@media (max-width: 75em) {
  html {
    font-size: 56.25%;
  }
}
@media (max-width: 56.25em) {
  html {
    font-size: 50%;
  }
}
@media (max-width: 150em) {
  html {
    font-size: 75%;
  }
}

.button:link, .button:visited {
  text-transform: uppercase;
  text-decoration: none;
  padding: 1rem 3rem;
  display: inline-block;
  text-align: center;
  border-radius: 10rem;
  transition: all 0.2s;
  position: relative;
  color: white;
  border: 1px solid white;
  background-color: #624870;
}
.button:active {
  transform: translateY(-3px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}
.button.resume_button {
  background-color: #624870;
  color: white;
  border: 1px solid white;
}

.home {
  height: 95vh;
  background-image: linear-gradient(to right bottom, rgba(255, 0, 0, 0.281), rgba(202, 166, 66, 0.233)), url("../src/img/pinetaNonna.jpg");
  background-size: cover;
  background-position: center;
  clip-path: polygon(0 0, 100% 0, 100% 75%, 0 97%);
}
@media (max-width: 56.25em) {
  .home {
    clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%);
  }
}
.home__welcome {
  margin-bottom: 6rem;
}
.home__welcome-container {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 90%;
  margin-bottom: 6rem;
  color: white;
}
@media (max-width: 75em) {
  .home__welcome-container {
    font-weight: bolder;
    top: 50%;
  }
}
.home__welcome--title {
  font-size: 4rem;
}
@media (max-width: 75em) {
  .home__welcome--title {
    font-size: 2.5rem;
  }
}

.portfolio {
  padding: 20rem 0;
  background-image: linear-gradient(to right bottom, rgba(202, 166, 66, 0.233), rgba(255, 0, 0, 0.281)), url("../src/img/pinetaNonna.jpg");
  background-size: cover;
  background-position: top;
  /* clip-path: polygon(0 20%, 100% 0, 100% 80%, 0 100%); */
  margin-top: -15rem;
}
@media (max-width: 37.5em) {
  .portfolio {
    padding: 5rem 0;
    clip-path: none;
  }
}
.portfolio h2 {
  position: relative;
  top: -2rem;
  font-size: 4rem;
  margin-top: ;
}

.projects {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  /* max-width: 80%; */
}
@media (max-width: 37.5em) {
  .projects {
    flex-direction: column;
    place-items: center;
  }
}

.projects__card {
  width: 20%;
  padding: 5% 0;
  text-decoration: none;
}
.projects__card .projects__card__text {
  height: 100%;
  color: white;
  border-radius: 0.5rem;
  border: 2px solid white;
  background-color: #624870;
}
@media (max-width: 37.5em) {
  .projects__card {
    width: 90%;
  }
}

.projects__image {
  max-width: 200px;
  margin-top: 20px;
}

#resume {
  padding: 5rem;
}

.about {
  padding: 5rem 0 15rem 0;
}
.about h2 {
  margin: 3% 0;
  font-size: 4rem;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 0% 5% 1% 5%;
  border-bottom: 1px solid black;
  z-index: 2;
}
.navbar__heading {
  align-self: center;
  font-weight: normal;
}
.navbar__heading--main {
  display: block;
  font-size: 3rem;
  letter-spacing: 0.5rem;
  font-weight: 700;
  border-bottom: 2px solid black;
}
.navbar__heading--sub {
  display: block;
  font-size: 2.5rem;
  letter-spacing: 2.2rem;
  font-weight: 700;
}

.menu {
  float: left;
  overflow: hidden;
  padding-bottom: 0.2rem;
}

.heading-secondary {
  font-size: 3.5rem;
  text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
  letter-spacing: 0.2rem;
  transition: all 0.2;
}
@media (max-width: 56.25em) {
  .heading-secondary {
    font-size: 3rem;
  }
}
@media (max-width: 37.5em) {
  .heading-secondary {
    font-size: 2.5rem;
  }
}
.heading-secondary:hover {
  text-shadow: 0.5rem 1rem 2rem rgba(0, 0, 0, 0.2);
}

.nav-button {
  color: black;
  padding: 0.5rem;
  text-decoration: none;
}
.nav-button:hover {
  color: white;
  background-color: #624870;
  border-radius: 0.5rem;
  box-shadow: 0 1px 6px -2px black;
}

.nav-content {
  min-width: 160px;
  background-color: white;
  border-radius: 0.5rem;
  z-index: 2;
}
@media (max-width: 75em) {
  .nav-content {
    display: none;
    position: absolute;
    right: 3%;
    border: 1px solid #624870;
  }
  .nav-content a {
    float: none;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }
}

.dropbtn {
  visibility: hidden;
  border: 1px solid white;
  background-color: #624870;
  border-radius: 0.5rem;
  color: white;
  padding: 0.5rem;
  font-size: 2rem;
}
@media (max-width: 75em) {
  .dropbtn {
    visibility: visible;
  }
}

.menu:hover .nav-content {
  display: block;
}

.navbar__heading {
  align-self: left;
  font-weight: normal;
}

.navbar__heading--main {
  display: block;
  font-size: 3rem;
  letter-spacing: 0.5rem;
  font-weight: 700;
  border-bottom: 2px solid black;
}
@media (max-width: 56.25em) {
  .navbar__heading--main {
    letter-spacing: 0.3rem;
    font-family: 2rem;
    font-size: 2rem;
  }
}
@media (max-width: 37.5em) {
  .navbar__heading--main {
    letter-spacing: 0.15rem;
    font-family: 2rem;
    font-size: 1.6rem;
  }
}

.navbar__heading--sub {
  display: block;
  font-size: 2.5rem;
  letter-spacing: 2.2rem;
  font-weight: 700;
}
@media (max-width: 56.25em) {
  .navbar__heading--sub {
    letter-spacing: 1rem;
    font-size: 2rem;
  }
}
@media (max-width: 37.5em) {
  .navbar__heading--sub {
    letter-spacing: 0.5rem;
    font-size: 1.3rem;
  }
}


.notes {
  margin-top: 10rem;
  text-align: left;
}

.locations {
  display: flex;
  justify-content: space-evenly;
}